import Button from "~components/ui/button";

export default function PostTypeDropdown({
  levels,
  setPostLevel,
  setIsPaid,
  setIsDropdownOpen,
}) {
  return (
    <div className="px-3 py-1 text-black">
      <Button
        onClick={() => {
          setPostLevel(null);
          setIsPaid(false);
          setIsDropdownOpen(false);
        }}
        className={postTypeButtonClasses}
      >
        For everybody
      </Button>

      {levels
        .filter((level) => level.isActive)
        .map((level, index) => (
          <Button
            onClick={() => {
              level && (setPostLevel(level), setIsPaid(true));
              setIsDropdownOpen(false);
            }}
            key={index}
            className={postTypeButtonClasses}
          >
            For{" "}
            <span className="text-pink-base">
              {level.title || `${level.index + 1} level`}
            </span>
          </Button>
        ))}
    </div>
  );
}
const postTypeButtonClasses = "py-1";
