// import "emoji-mart/css/emoji-mart.css";
import { Picker } from "emoji-mart";

const SelectEmojiTable = ({ pickerStyle, perLine = 9, handleSelectEmoji }) => {
  return (
    <Picker
      style={pickerStyle}
      perLine={perLine + 1}
      onClick={handleSelectEmoji}
    />
  );
};
export default SelectEmojiTable;
