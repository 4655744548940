import ReactMarkdown from "react-markdown";
import Files from "../files";

const PostContent = ({ post, hideText = false }) => {
  const { text, media } = post;

  return (
    <>
      {text && !hideText ? (
        <div
          className={`markdown text-xs md:text-14px font-family-montserrat-medium leading-[21px] md:leading-[24px] mb-3 whitespace-pre-line break-words ${
            media?.length ? "md:mb-4" : "md:mb-8"
          }`}
        >
          <ReactMarkdown>{text}</ReactMarkdown>
        </div>
      ) : null}
      {media?.length ? <Files files={media} author={post.user} /> : null}
    </>
  );
};

export default PostContent;
