import { useState, useMemo } from "react";
import DropdownButton from "../dropdown-button";

const PostDropdownItems = ({ username, setShowReportCard }) => {
  const onReport = () => {
    setShowReportCard(true);
  };

  const onShare = () => {};
  const onFollow = () => {};

  const buttons = useMemo(() => {
    const buttonsArray = [
      { label: "Report", onClick: onReport, icon: "Report" },
    ];

    // if (username)
    //   buttonsArray.push({
    //     label: `Follow @${username}`,
    //     onClick: onFollow,
    //     icon: `Follow`,
    //   });

    return buttonsArray;
  }, [username]);

  return (
    <div className="flex flex-col">
      {buttons?.length
        ? buttons.map((button, index) => {
            return <DropdownButton {...button} variant="light" key={index} />;
          })
        : null}
    </div>
  );
};

export default PostDropdownItems;
