import { useEffect, useState } from "react";
import CardBox from "~components/ui/card-box";
import PostHeader from "./post-header";
import PostContent from "./post-content";
import PostFooter from "./post-footer";

import FeedForm from "~components/feed/feed-form";
import EmptyPlaceholder from "~components/ui/empty-placeholder";
import Modal from "~components/popups/modal";
import ReportCard from "./report-card";
import MobileModal from "~components/popups/mobile-modal";

import { setSubscribeModal } from "~store/modals";
import useProfile from "~services/backend/profiles/useProfile";
import { isMobile } from "react-device-detect";
import { useDispatch } from "react-redux";

const FeedCard = ({
  isHidden,
  className = "",
  post = {},
  isLoading = false,
}) => {
  const { profile } = useProfile();
  const isMyPost = post.user && post.user?.id === profile?.id;

  const [isPostDeleted, setPostDeleted] = useState(false);
  const [enableEditing, setEnableEditing] = useState(false);

  const [showReportCard, setShowReportCard] = useState(false);

  const [postLikes, setPostLikes] = useState({
    userLike: false,
    likesCount: 0,
  });

  const disableEditing = () => setEnableEditing(false);

  const onUpdatePostSucces = () => {
    setEnableEditing(false);
  };

  const { subscriptionLevel } = post || {};
  const tierInfo = subscriptionLevel
    ? subscriptionLevel.title || `${subscriptionLevel.index + 1} level`
    : null;

  useEffect(() => {
    if (post.likesCount)
      setPostLikes({ likesCount: post.likesCount, userLike: post.userLike });
  }, [post?.userLike, post?.likesCount]);

  if (isLoading)
    return (
      <CardBox
        id={post?.id ? `post-${post.id}` : null}
        className={`@wh w-full lg:max-w-full @mn mb-6 @pg p-4 md:p-8 @brc border border-[#F5F5F5] ${
          isHidden && "!border-0 "
        } shadow-base ${className}`}
      >
        <PostLoadingPlaceholder />
      </CardBox>
    );

  if (isPostDeleted)
    return (
      <div className="flex flex-col items-center justify-center gap-6 p-8">
        <EmptyPlaceholder
          title="Post is deleted"
          containerClassName="justify-center"
        />
      </div>
    );

  return (
    <CardBox
      id={post?.id ? `post-${post.id}` : null}
      className={`@wh w-full lg:max-w-full @mn mb-6 @pg p-4 md:p-8 @brc border border-[#F5F5F5] ${
        isHidden && "!border-0 "
      } shadow-base ${className}`}
    >
      <RenderModal
        post={post}
        showReportCard={showReportCard}
        setShowReportCard={setShowReportCard}
      />
      <PostHeader
        post={post}
        isMyPost={isMyPost}
        setPostDeleted={setPostDeleted}
        enableEditing={enableEditing}
        setEnableEditing={setEnableEditing}
        setShowReportCard={setShowReportCard}
        tierInfo={tierInfo}
      />
      {enableEditing ? (
        <>
          <FeedForm
            disableFiles={true}
            post={post}
            onCancelButtonClick={disableEditing}
            onCreateCallback={onUpdatePostSucces}
          />
          <PostContent hideText={true} post={post} />
        </>
      ) : (
        <>
          <PostContent post={post} />
          <PostFooter setPostLikes={setPostLikes} {...postLikes} post={post} />
        </>
      )}
    </CardBox>
  );
};

export default FeedCard;

const bgColor = "bg-zinc-light animate-pulse";
function PostLoadingPlaceholder() {
  return (
    <div className="w-full">
      <div className="flex items-center mb-8">
        <div
          className={`w-[50px] h-[50px] shrink-0 ${bgColor} rounded-full mr-3`}
        />
        <div className="w-3/4">
          <div className={`w-2/3 h-[12px] ${bgColor} rounded-md mb-2`}></div>
          <div className={`w-1/3 h-[12px] ${bgColor} rounded-md`}></div>
        </div>
      </div>
      <div className="">
        <div className={`w-full h-[14px] ${bgColor} mb-2.5 rounded-md`}></div>
        <div className={`w-full h-[14px] ${bgColor} mb-2.5 rounded-md`}></div>
        <div className={`w-2/3 h-[14px] ${bgColor} mb-5 rounded-md`}></div>
        <div className={`w-full h-[200px] ${bgColor} mb-1 rounded-md`}></div>
      </div>
    </div>
  );
}

const RenderModal = ({ post, showReportCard, setShowReportCard }) => {
  return isMobile ? (
    <MobileModal
      RenderCard={ReportCard}
      show={showReportCard}
      setShow={setShowReportCard}
      renderCardProps={{
        post,
        setShowReportCard,
      }}
      renderCardContainerClasses="@ow overflow-scroll  @wh w-auto @pg p-4"
    />
  ) : (
    <Modal
      RenderCard={DesktopReport}
      show={showReportCard}
      setShow={setShowReportCard}
      renderCardProps={{
        post,
        setShowReportCard,
      }}
      childContainerClasses={"@wh w-full lg:w-fit"}
      containerClasses={"@pg px-2 py-5 md:px-4 md:py-10"}
    />
  );
};

const DesktopReport = (props) => {
  return (
    <CardBox className="@wh w-90vw lg:w-40vw">
      <ReportCard {...props} />
    </CardBox>
  );
};
