import Button from "~components/ui/button";
import AllIcons from "~components/zui/components/all-icons";

const DropdownButton = ({ variant, label, icon, iconClasses, ...props }) => (
  <Button {...props} className={buttonBaseClasses} variant={variant}>
    <AllIcons
      name={icon}
      className={`@wh w-4 @ht h-4 @mn mr-3 ${iconClasses}`}
    />
    <span className="font-family-inter-medium text-12px leading-18px">
      {label}
    </span>
  </Button>
);

export default DropdownButton;

const buttonBaseClasses = `
  @brr rounded-8px 
  @dy flex
  @ani items-center 
  @pg p-3 pr-7
  @ttc text-zinc-base hover:text-black
`;
