import React from "react";

import AllIcons from "~components/zui/components/all-icons";
import Avatar from "~components/ui/avatar";
import P from "~components/ui/typography/p";
import Link from "next/link";
import { formatAddress } from "~utils/transformers";
import { getSocialImagePath } from "~utils/vanilla/formatters/get-social-image-path";

const UserInfo = ({ user, tierInfo, button, timeFromCreate, className }) => {
  const { address, username, image, isVerified } = user;

  return (
    <div
      className={`flex flex-row items-center justify-between mb-8 ${className}`}
    >
      <div className="flex items-center">
        <Link href={`/${username}`} passHref={true}>
          <a>
            <Avatar
              className="!w-[40px] !h-[40px] md:!w-[50px] md:!h-[50px]"
              src={getSocialImagePath(image) || "/assets/images/avatar.jpg"}
            />
          </a>
        </Link>
        <div className="flex flex-col ml-2.5">
          <div className="flex flex-row items-center">
            <Link href={`/${username}`} passHref={true}>
              <a>
                <P className="@fts text-[14px] md:text-base @ftw font-clash font-semibold @ttc text-zinc-900 hover:text-pink-base @tm lowercase @tndn duration-200">
                  {`@${formatAddress(username)}`}
                </P>
              </a>
            </Link>
            {isVerified && (
              <AllIcons
                name="Confirmed"
                className="w-4 h-4 ml-1.5 -mt-[2px] text-pink-base md:mt-0 md:h-5 md:w-5"
              />
            )}
            <P className="@fts text-xs md:text-sm @ttc text-zinc-base ml-1.5">
              {timeFromCreate}
            </P>
          </div>
          <div className="flex">
            <P className="@fts text-xs md:text-sm @ttc text-zinc-base">
              {formatAddress(address)}
            </P>
            {tierInfo && (
              <span className="font-montserrat font-semibold text-12px md:leading-18px py-[1px] md:py-0.5 px-3 bg-lime-base ml-1.5 rounded-full text-center">
                {tierInfo}
              </span>
            )}
          </div>
        </div>
      </div>
      {button || null}
    </div>
  );
};
export default UserInfo;
