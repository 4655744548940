import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import updateLocale from "dayjs/plugin/updateLocale";

dayjs.extend(relativeTime);
dayjs.extend(updateLocale);

dayjs.updateLocale("en", {
  relativeTime: {
    future: "%d s",
    past: "%d s",
    s: "%d s",
    m: "1 m",
    mm: "%d m",
    h: "1 h",
    hh: "%d h",
    d: "1 d",
    dd: "%d d",
    M: "1 M",
    MM: "%d M",
    y: "1 y",
    yy: "%d y",
  },
});

const formatDate = (since, until = new Date()) => {
  const [sinceTimestamp, untilTimestamp] = [
    dayjs(since).unix(),
    dayjs(until).unix(),
  ];

  if (untilTimestamp - sinceTimestamp < 60 * 60 * 24) {
    return dayjs(since).from(until, true);
  }

  return dayjs(since).format("MMM DD, YYYY");
};

export default {
  formatDate,
};
