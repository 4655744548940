import DropdownButton from "../dropdown-button";
import { postsApi, useDeletePostMutation } from "~services/backend/posts";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import useProfile from "~services/backend/profiles/useProfile";

const PostAuthorDropdownItems = ({
  post,
  enableEditing,
  setEnableEditing,
  setIsDropdownOpen,
}) => {
  const { profile } = useProfile();
  const dispatch = useDispatch();
  const onEdit = () => {
    setEnableEditing(true);
    setIsDropdownOpen();
  };

  const [deletePost, { isLoading: isPostDeleting, isSuccess }] =
    useDeletePostMutation();

  const buttons = [
    {
      label: "Edit text",
      onClick: onEdit,
      icon: "Edit",
      disabled: enableEditing,
    },
    // {
    //   label: `Share`,
    //   onClick: onShare,
    //   icon: `Share`,
    // },
    {
      label: "Delete",
      onClick: () => deletePost(post.id),
      icon: isPostDeleting ? "SmallLoader" : "Bin",
      iconClasses: isPostDeleting ? "animate-spin" : "",
    },
  ];

  useEffect(() => {
    if (!isSuccess) return;

    const updateCb = (draft = []) => {
      if (!draft.length) return;

      const postIndex = draft.findIndex((p) => p.id === post.id);

      if (postIndex !== -1) draft.splice(postIndex, 1);
    };
    dispatch(postsApi.util.updateQueryData("getPostsFeed", {}, updateCb));
    dispatch(
      postsApi.util.updateQueryData(
        "getProfilePosts",
        { userId: profile.id },
        updateCb,
      ),
    );
  }, [isSuccess]);

  return (
    <div className="flex flex-col">
      {buttons?.length
        ? buttons.map((button, index) => (
            <DropdownButton {...button} variant="light" key={index} />
          ))
        : null}
    </div>
  );
};

export default PostAuthorDropdownItems;
