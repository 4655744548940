import UserInfo from "~components/feed/feed-card/user-info";
import dateUtils from "~utils/date-utils";
import Button from "~components/ui/button";
import AllIcons from "~components/zui/components/all-icons";
import PostDropdownItems from "../post-dropdowns/post-dropdown-items";
import PostAuthorDropdownItems from "../post-dropdowns/post-author-dropdown-items";

const PostHeader = ({
  post,
  isMyPost,
  setPostDeleted,
  enableEditing,
  setEnableEditing,
  setShowReportCard,
  tierInfo,
}) => {
  const { user, createdAt } = post;
  const timeFromCreate = dateUtils.formatDate(createdAt);

  const dropdownItems = isMyPost ? PostAuthorDropdownItems : PostDropdownItems;

  const dropdownProps = isMyPost
    ? { setPostDeleted, post, enableEditing, setEnableEditing }
    : { username: user?.username, post, setShowReportCard };

  return (
    user && (
      <UserInfo
        user={user}
        timeFromCreate={timeFromCreate}
        className="w-full"
        tierInfo={tierInfo}
        button={
          <Button
            className="w-5 h-5 @ow overflow-hidden flex items-center justify-center cursor-pointer @ttc text-zinc-base hover:text-black self-start"
            dropdownContainerClasses={dropdownContainerClasses}
            dropdownItems={dropdownItems}
            dropdownProps={dropdownProps}
          >
            <AllIcons name="Dots" className="w-5" />
          </Button>
        }
      />
    )
  );
};

export default PostHeader;

const dropdownContainerClasses = `
  @it -right-2 top-14
  @pg p-2
  @zi z-20
  @brr rounded-18px bg-white
  @brc border-true-gray-750
  @bxsw shadow-200
  @wh w-auto
`;
