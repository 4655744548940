import { useMemo, useState } from "react";
import {
  getSocialImagePath,
  getSocialFilePath,
} from "~utils/vanilla/formatters/get-social-image-path";
import Button from "~components/ui/button";
import AllIcons from "~components/ui/all-icons";

import { parseMimeType } from "~utils/vanilla";
import BluredContent from "../post-content/BluredContent";
import { useDispatch } from "react-redux";
import { setMediaGalleryModal, setSubscribeModal } from "~store/modals";
import VideoPreview from "~components/media/VideoPreview";

export default function Files({ files, author }) {
  const media = useMemo(() => {
    const preparedAndFiltered = [];
    files.forEach((file) => {
      const { renderType } = parseMimeType(file?.mime);

      if (renderType === "image" || renderType === "video") {
        preparedAndFiltered.push({ ...file, renderType });
      }
    });
    return preparedAndFiltered;
  }, [files]);

  const otherFiles = useMemo(
    () =>
      files.length
        ? files.filter((file) => {
            if (!file.mime) return true;
            const { renderType } = parseMimeType(file?.mime);
            return file.url && renderType === "file";
          })
        : [],
    [files],
  );

  return (
    <div className="mb-3 w-full">
      {media?.length ? (
        <MediaPreviewGrid media={media} author={author} />
      ) : null}

      {otherFiles.length
        ? otherFiles.map((file, index) => {
            const fileSize =
              file.size < 1_048_576
                ? `${(file.size / 1024).toFixed(1)} Mb`
                : `${(file.size / 1_048_576).toFixed(2)} Gb`;
            return (
              <a
                key={index}
                href={`${getSocialFilePath(file)}`}
                target="_blank"
                download={file.name}
                rel="noreferrer"
                className="bg-white border hover:border-pink-base shadow hover:shadow-none text-black hover:text-pink-base group py-2 px-2 rounded-lg relative flex items-center justify-between"
              >
                <div className="flex">
                  <Button
                    variant="text"
                    className={
                      "@ttc text-zinc-base group-hover:text-pink-base @mn mr-1 @zi z-30 @pn relative"
                    }
                  >
                    <AllIcons name="File" className="w-7 mr-2" />
                  </Button>

                  <div className="flex flex-col">
                    <span className="text-sm font-medium">{file.name}</span>
                    <span className="text-zinc-400 text-sm">{fileSize}</span>
                  </div>
                </div>
              </a>
            );
          })
        : null}
    </div>
  );
}

const blurUrl = "/assets/images/blur.jpg";
const MediaPreviewGrid = ({ media = [], author }) => {
  const [loadingImages, setLoadingImages] = useState([]);
  const dispatch = useDispatch();

  const setShowSubscribeModal = () =>
    dispatch(
      setSubscribeModal({
        profile: author,
        show: true,
      }),
    );

  const { gridConfig, gridMedia } = useMemo(() => {
    const length = media.length;

    if (length >= 7)
      return { gridConfig: gridConfigs[7], gridMedia: media.slice(0, 7) };

    return { gridConfig: gridConfigs[length], gridMedia: media };
  }, [media]);

  const showGallery = (index) => {
    dispatch(
      setMediaGalleryModal({
        show: true,
        media,
        mediaIndex: index,
      }),
    );
  };

  return (
    <div
      className={`w-full grid gap-3 overflow-hidden ${gridConfig.container}`}
    >
      {gridMedia.map((mediaObj, index) => (
        <div
          key={index}
          className={`grid ${gridConfig.elements[index]} overflow-hidden rounded-10px relative max-h-80vh`}
        >
          {mediaObj.isHidden &&
            (gridMedia.length === 1 ? (
              <div
                className="absolute inset-0 cursor-pointer"
                onClick={() => showGallery(index)}
              >
                <BluredContent setShowSubscribeModal={setShowSubscribeModal} />
              </div>
            ) : (
              <img
                onClick={() => setShowSubscribeModal(true)}
                src={"/assets/images/white-locked.svg"}
                className="cursor-pointer absolute h-full  min-w-12 min-h-12 w-1/4 object-contain self-center justify-self-center mx-auto inset-0 "
              />
            ))}
          {mediaObj.renderType === "image" || mediaObj.isHidden ? (
            <img
              src={
                loadingImages[index]
                  ? getSocialImagePath(mediaObj) || blurUrl
                  : blurUrl
              }
              alt={mediaObj.name}
              onClick={() => showGallery(index)}
              onLoad={() =>
                setLoadingImages((prev) => {
                  const newState = [...prev];
                  newState[index] = true;
                  return newState;
                })
              }
              className="w-full h-full object-cover cursor-pointer max-h-80vh"
            />
          ) : gridMedia.length === 1 ? (
            <video
              className="w-full cursor-pointer max-h-80vh"
              controls
              src={getSocialFilePath(mediaObj)}
            />
          ) : (
            <div
              onClick={() => showGallery(index)}
              className="w-full h-auto max-h-80vh pb-full relative overflow-hidden cursor-pointer"
            >
              <VideoPreview
                url={getSocialFilePath(mediaObj)}
                mime={mediaObj.mime}
                className="absolute object-cover block w-full h-full max-w-full max-h-full"
              />
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

const gridConfigs = {
  1: {
    container: "",
    elements: [],
  },
  2: {
    container: "grid-cols-2",
    elements: ["col-span-1", "col-span-1"],
  },
  3: {
    container: "grid-cols-10 grid-rows-2",
    elements: ["col-span-7 row-span-2", "col-span-3", "col-span-3"],
  },
  4: {
    container: "grid-cols-4 grid-rows-3",
    elements: [
      "col-span-3 row-span-3",
      "col-span-1",
      "col-span-1",
      "col-span-1",
    ],
  },
  5: {
    container: "grid-cols-6 grid-rows-3",
    elements: [
      "col-span-2 row-span-1",
      "col-span-2 row-span-1",
      "col-span-2 row-span-1",
      "col-span-3 row-span-2",
      "col-span-3 row-span-2",
    ],
  },
  6: {
    container: "grid-cols-3 grid-rows-2",
    elements: [
      "col-span-1",
      "col-span-1",
      "col-span-1",
      "col-span-1",
      "col-span-1",
      "col-span-1",
    ],
  },
  7: {
    container: "grid-cols-10 grid-rows-4",
    elements: [
      "col-span-5 row-span-3",
      "col-span-5 row-span-3",
      "col-span-2",
      "col-span-2",
      "col-span-2",
      "col-span-2",
      "col-span-2",
    ],
  },
};
