import { useState, useRef } from "react";

import CardBox from "~components/ui/card-box";

import TextareaAutosize from "react-textarea-autosize";
import AllIconsLocal from "~components/ui/all-icons";
import Button from "~components/ui/button";
import useProfile from "~services/backend/profiles/useProfile";
import SelectEmojiTable from "~components/tables/select-emoji-table";
import createEventNotification, {
  handleApiError,
} from "~utils/create-event-notification";
import { allowedImageExtensions } from "~configs";
import { parseMimeType } from "~utils/vanilla";
import {
  postsApi,
  useAddPostMutation,
  useEditPostMutation,
  useLazyGetPostsQuery,
} from "~services/backend/posts";
import PostTypeDropdown from "./PostTypeDropdown";
import { useDispatch } from "react-redux";
import AllIcons from "~components/zui/components/all-icons";

export default function FeedForm({
  post = {},
  disableFiles = false,
  onCreateCallback = () => {},
}) {
  const dispatch = useDispatch();
  const { profile } = useProfile();
  const { subscriptionLevels: levels = [] } = profile || {};

  const [editPost] = useEditPostMutation();
  const [addPost] = useAddPostMutation();

  const [getPosts] = useLazyGetPostsQuery();

  const [postLevel, setPostLevel] = useState(post.subscriptionLevel || null);
  const [isPaid, setIsPaid] = useState(postLevel || null);
  const [isLoading, setIsLoading] = useState(false);

  const [text, setText] = useState(post.text);
  const [uploadFiles, setUploadFiles] = useState([]);
  const [filesConfigurations, setFilesConfigurations] = useState([]);

  const inputRef = useRef(null);

  const submitDisabled = !text?.length && !uploadFiles?.length;

  const handleEmojiClick = (emoji) => {
    inputRef.current.focus();
    const selectionStart = inputRef.current.selectionStart;
    const currentValue = inputRef.current.value;
    const newValue =
      currentValue.substring(0, selectionStart) +
      emoji.native +
      currentValue.substring(selectionStart);
    setText(newValue);
  };

  const handleFileUploader = (e, config = {}) => {
    if (!e.target?.files?.length) return;

    const loadedFiles = { ...e.target.files };
    let uploadedFilesSize = 0;

    for (const [index] of new Array(e.target.files.length).entries()) {
      const size = loadedFiles[index].size / (1024 * 1024);
      const type = loadedFiles[index].type.split("/")[0];

      if (
        config?.shouldCompress !== true &&
        uploadedFilesSize + size > profile.free_space
      ) {
        createEventNotification({
          event: "error",
          title: `You have only ${profile?.free_space?.toFixed(
            0,
          )}MB of free space, you could buy additional space very soon.`,
        });

        e.target.value = null;
        break;
      }

      if (type === "image" && config.shouldCompress === true) {
        setUploadFiles((prevState) => [...prevState, loadedFiles[index]]);
        setFilesConfigurations((prevState) => [...prevState, { ...config }]);
      } else {
        setUploadFiles((prevState) => [...prevState, loadedFiles[index]]);
        setFilesConfigurations((prevState) => [...prevState, {}]);
      }
      uploadedFilesSize += size;
    }

    e.target.value = null;
  };

  const removeItem = (id) => {
    setUploadFiles(uploadFiles.filter((_, index) => index !== id));
    setFilesConfigurations(
      filesConfigurations.filter((_, index) => index !== id),
    );
  };

  const createPost = async () => {
    if (!profile) return;

    let formData = new FormData();

    const data = {
      text,
      is_private: isPaid,
      subscription_level: postLevel ? postLevel.id : null,
      "files.media": filesConfigurations,
    };

    if (uploadFiles) {
      for (const [_, file] of uploadFiles.entries()) {
        formData.append("files.media", file);
      }
    }

    formData.append("data", JSON.stringify(data));

    setIsLoading(true);
    let newPost;
    if (post.id) {
      const { data: apiPost, error } = await editPost({
        id: post.id,
        data: formData,
      });
      newPost = apiPost;

      if (error) {
        console.error("editPost", error);

        createEventNotification(handleApiError(error));
      }
    } else {
      const { data: apiPost, error } = await addPost(formData);

      newPost = apiPost;

      if (error) {
        console.error("addPost error", error);

        createEventNotification(handleApiError(error));
      }
    }

    setIsLoading(false);
    if (newPost?.id) {
      if (uploadFiles) {
        for (const [index] of uploadFiles.entries()) {
          removeItem(index);
        }
      }

      const { data = [] } = await getPosts({
        filters: {
          id: newPost.id,
        },
      });

      const [newPostFromApi] = data;

      const updateCb = (draft) => {
        if (!draft.length || !newPostFromApi) return;

        if (post.id) {
          draft.forEach((p, index) => {
            if (p.id === newPost.id) {
              draft[index] = newPostFromApi;
            }
          });
        } else {
          draft.unshift(newPostFromApi);
        }
      };
      dispatch(postsApi.util.updateQueryData("getPostsFeed", {}, updateCb));
      dispatch(
        postsApi.util.updateQueryData(
          "getProfilePosts",
          { userId: profile.id },
          updateCb,
        ),
      );

      setText("");
      setIsPaid(false);
      setPostLevel(null);
      onCreateCallback();
      setUploadFiles([]);
    }
  };

  return (
    <CardBox
      className={`@dy flex @zi z-10 @brw border-0 border-[#F5F5F5]  ${
        post.id ? "@pg p-0 @bdc bg-transparent" : "@mn mb-6"
      }`}
    >
      <div className={"w-full"}>
        <TextareaAutosize
          value={text}
          onChange={(e) => setText(e.target.value)}
          minRows={1}
          maxRows={8}
          placeholder="Share something"
          className={`w-full @pg p-1 @ht h-100px max-h-[70vh] min-h-100px @brw ${
            post.id
              ? "border border-opacity-40 border-[#F5F5F5] hover:border-[#F5F5F5]"
              : "!border-0 outline-0"
          }`}
        />
        <div className="flex max-w-full overflow-x-scroll">
          {uploadFiles.length
            ? uploadFiles.map((uploadFile, index) => {
                const { ext } = parseMimeType(uploadFile?.type);

                if (!uploadFile || !allowedImageExtensions.includes(ext)) {
                  return null;
                }

                const url = URL.createObjectURL(uploadFile);

                return (
                  <div
                    key={index}
                    className="overflow-hidden rounded-16px md:mb-3 relative w-[110px] h-[110px] md:w-[150px] md:h-[150px] mr-2 shrink-0 "
                  >
                    {!post.id ? (
                      <Button
                        variant="text"
                        className="@bdc bg-white @pn absolute top-1.5 right-1.5 @brr rounded-full"
                        onClick={() => removeItem(index)}
                      >
                        <AllIcons name="Close" className="w-6" />
                      </Button>
                    ) : null}
                    <img
                      src={url}
                      alt="Image"
                      className="w-full object-cover"
                    />
                  </div>
                );
              })
            : null}
        </div>
        {uploadFiles.length
          ? uploadFiles.map((uploadFile, index) => {
              const { type } = parseMimeType(uploadFile?.type);

              if (!uploadFile || type !== "video") {
                return null;
              }

              const url = URL.createObjectURL(uploadFile);

              return (
                <div
                  key={index}
                  className="overflow-hidden rounded-16px mb-3 relative"
                >
                  {!post.id ? (
                    <Button
                      variant="text"
                      className="@bdc bg-white @pn absolute top-1.5 right-1.5 @brr rounded-full z-10"
                      onClick={() => removeItem(index)}
                    >
                      <AllIcons name="Close" className="w-6" />
                    </Button>
                  ) : null}
                  <video controls muted>
                    <source src={url} type="video/mp4" />
                  </video>
                </div>
              );
            })
          : null}
        {uploadFiles.length
          ? uploadFiles.map((uploadFile, index) => {
              const { type, ext } = parseMimeType(uploadFile?.type);

              if (
                !uploadFile ||
                (type === "image" && allowedImageExtensions.includes(ext)) ||
                type === "video"
              ) {
                return null;
              }

              return (
                <div
                  key={index}
                  className="overflow-hidden rounded-16px mb-3 relative flex items-center"
                >
                  <Button
                    variant="text"
                    className={
                      "@ttc text-zinc-base hover:text-black @mn mr-1 @zi z-30 @pn relative"
                    }
                  >
                    <AllIconsLocal name="File" className="w-5" />
                  </Button>
                  {uploadFile.name}
                  {!post.id ? (
                    <Button
                      variant="text"
                      className="@bdc bg-white @brr rounded-full z-10 @mn ml-auto"
                      onClick={() => removeItem(index)}
                    >
                      <AllIcons name="Close" className="w-6" />
                    </Button>
                  ) : null}
                </div>
              );
            })
          : null}
        <div className="w-full mb-4 md:hidden lg:flex xl:hidden">
          {!post.id && levels.length ? (
            <Button
              variant="rounded"
              className="@pn relative @wh w-full @bdc bg-stone-base hover:bg-lime-base @ttc text-zink-900 flex justify-center @ftf font-semibold z-55 @ht max-h-11"
              dropdownItems={PostTypeDropdown}
              dropdownProps={{
                levels,
                setPostLevel,
                setIsPaid,
              }}
            >
              {isPaid ? (
                <span>
                  For{" "}
                  <span className="text-pink-base">
                    {postLevel.title || `${postLevel.index + 1} level`}
                  </span>
                </span>
              ) : (
                "For everybody"
              )}
              <AllIconsLocal
                name="ArrowDown"
                className="w-5 ml-2.5 -mt-[2px]"
              />
            </Button>
          ) : null}
        </div>
        <div className="flex flex-row items-center justify-between">
          {disableFiles ? null : (
            <div className="flex w-full md:w-fit flex-row items-center mb-0 justify-between pr-8 md:pr-0">
              <Button
                variant="text"
                dropdownContainerClasses="@pn absolute @wh w-[280px]
              border-none @brc border-true-gray-150 @bdc bg-white dark:bg-true-gray-850 @ow overflow-hidden @brc dark:border-true-gray-750 @it 2xl:-right-2 -left-2 2xl:left-auto top-full"
                dropdownItems={() => (
                  <SelectEmojiTable
                    handleSelectEmoji={handleEmojiClick}
                    pickerStyle={{ width: "100%" }}
                  />
                )}
                className={iconButtonClassNames}
              >
                <AllIconsLocal name="Smile" className="w-5" />
              </Button>

              <label htmlFor={"imageUploader"}>
                <Button variant="text" className={iconButtonClassNames}>
                  <AllIconsLocal name="Picture" className="w-5" />
                </Button>
                <input
                  type="file"
                  name="file"
                  accept="image/jpeg,image/png,video/*"
                  id={"imageUploader"}
                  onChange={(e) =>
                    handleFileUploader(e, { shouldCompress: true })
                  }
                  className="hidden"
                  multiple
                />
              </label>

              <label htmlFor="fileUploader">
                <Button variant="text" className={iconButtonClassNames}>
                  <AllIconsLocal name="File" className="w-5" />
                </Button>
                <input
                  type="file"
                  name="file"
                  accept="*/*"
                  id="fileUploader"
                  onChange={(e) => handleFileUploader(e)}
                  className="hidden"
                  multiple
                />
              </label>
            </div>
          )}
          <div
            className={`${
              disableFiles
                ? `w-full justify-end ${post.media?.length ? "mb-4" : ""}`
                : ""
            } flex`}
          >
            {!post.id && levels.length ? (
              <Button
                variant="rounded"
                className="@pn relative @wh w-full md:w-fit @bdc bg-stone-base hover:bg-lime-base @dy hidden md:flex @ttc text-zink-900 @mn mr-7 lg:hidden xl:flex @ht max-h-11"
                dropdownItems={PostTypeDropdown}
                dropdownProps={{
                  levels,
                  setPostLevel,
                  setIsPaid,
                }}
              >
                {isPaid ? (
                  <span>
                    For{" "}
                    <span className="text-pink-base">
                      {postLevel.title || `${postLevel.index + 1} level`}
                    </span>
                  </span>
                ) : (
                  "For everybody"
                )}
                <AllIconsLocal
                  name="ArrowDown"
                  className="w-5 ml-2.5 -mt-[2px]"
                />
              </Button>
            ) : null}
            <Button
              onClick={createPost}
              disabled={submitDisabled || isLoading}
              variant="rounded"
              className="@wh w-full md:w-[100px] @bdc bg-pink-base lg:hover:bg-lime-base @ht h-11 flex justify-center items-center"
            >
              {isLoading ? (
                <AllIconsLocal
                  name="SmallLoader"
                  className="h-5 w-5 animate-spin flex justify-center items-center"
                />
              ) : post.id ? (
                "Save"
              ) : (
                "Publish"
              )}
            </Button>
          </div>
        </div>
      </div>
    </CardBox>
  );
}

const iconButtonClassNames =
  "@ttc text-zinc-base hover:text-black @mn md:mr-4 @zi z-30 @pn relative";
