import Button from "~ui/button";
import Widget from "~components/widget";

const TagsCloud = ({ tagsList }) => {
  return tagsList?.length ? (
    <Widget title="Popular categories" href="/">
      <div className="flex flex-wrap justify-start gap-2">
        {tagsList.map(
          (tag, index) =>
            tag?.title && (
              <Button
                variant="rounded"
                href={"/search?"}
                className="@bdc bg-stone-base hover:bg-lime-base @ttc text-zinc-900"
                key={index}
                index={index}
              >
                {tag.title}
              </Button>
            ),
        )}
      </div>
    </Widget>
  ) : null;
};

export default TagsCloud;
