import CardBox from "~ui/card-box";
import P from "~ui/typography/p";
import Button from "~ui/button";

export default function Widget({ title, href, children }) {
  return (
    <CardBox className="w-full @brw border border-[#F5F5F5] @pg p-[30px] @mn mb-5 overflow-hidden shadow-small">
      <div className="flex justify-between items-baseline mb-5">
        <P className="font-clash font-semibold text-[16px] @ttc text-zinc-900">
          {title}
        </P>
        {href ? (
          <Button
            href={href}
            passHref={true}
            variant="text"
            className="@fts text-[14px]"
          >
            Read more
          </Button>
        ) : null}
      </div>
      {children}
    </CardBox>
  );
}
