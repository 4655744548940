import H2 from "~components/ui/typography/h2";
import P from "~components/ui/typography/p";

const EmptyPlaceholder = ({
  title = "",
  subTitle = "",
  containerClassName = "",
}) => (
  <div
    className={`text-center mx-auto max-w-lg flex flex-col gap-2 ${containerClassName}`}
  >
    {title ? (
      <P className="@ttc text-zinc-base @ftf font-clash @ftw font-semibold @mn mx-auto @tta text-center @fts text-xl">
        {title}
      </P>
    ) : null}
    {subTitle ? (
      <P
        variant="1"
        className="@ttc text-true-gray-450 @mn mx-auto @tta text-center"
      >
        {subTitle}
      </P>
    ) : null}
  </div>
);

export default EmptyPlaceholder;
