import { useEffect, useState } from "react";
import P from "~components/ui/typography/p";
import SettingsLabel from "~components/settings/settings-label";
import TextInput from "~components/input/text-input";
import TextArea from "~components/input/textarea";
import Button from "~components/ui/button";
import { useCreateReportMutation } from "~services/backend/profiles";

export default function ReportCard({ post, setShowReportCard = () => {} }) {
  const [title, setTitle] = useState("");
  const [text, setText] = useState("");
  const [titleError, setTitleError] = useState();
  const [textError, setTextError] = useState();

  const [createReport] = useCreateReportMutation();

  useEffect(() => {
    if (title) {
      setTitleError();
    }
    if (text) {
      setTextError();
    }
  }, [title, text]);

  const onFormSubmit = async (e) => {
    if (!title || !text) {
      if (!title) {
        setTitleError({ message: "Required field" });
      }
      if (!text) {
        setTextError({ message: "Required field" });
      }
      return;
    }

    const { data: report } = await createReport({
      postId: post.id,
      title,
      text,
    });

    if (report) {
      setTitle("");
      setText("");
      setShowReportCard(false);
    }
  };

  return (
    <>
      <SettingsLabel
        label="Report about post"
        description="You can complain about content that is posted on the platform, if our
          moderators deem the content indecent to be found on subclub.me, we
          will remove it"
        className="!w-full mb-5 lg:mb-10"
      />
      <TextInput
        label="Title"
        value={title}
        error={titleError}
        onChange={(e) => setTitle(e?.target?.value)}
        inputsClassName="@bdc bg-transparent @brc border-zinc-base focus:border-pink-base @bro border-opacity-100 @ttc text-black @ftf font-montserrat @fts text-base"
      />
      <TextArea
        value={text}
        error={textError}
        onChange={(e) => setText(e?.target?.value)}
        label="Description"
        inputsClassName="@brw border @pg p-3 @bdc bg-transparent @brc border-zinc-base focus:border-pink-base @bro border-opacity-100 @ttc text-black @ftf font-montserrat @ftw font-regular @fts text-base"
      />
      <Button
        onClick={onFormSubmit}
        variant="rounded"
        className="@mn mx-auto @wh w-fit"
      >
        <P>Send report</P>
      </Button>
    </>
  );
}
